import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { SmartCoinOrderDetailsModel } from '@shared/models/smart-coin-order-details/smart-coin-order-details-model';
import { SmartCoinOrderStatusModel } from '@shared/models/smart-coin-order-status/smart-coin-order-status-model';
import { TransacrtionDetailsModel } from '@shared/models/transactions/transactions-detail-model';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { IConfigService } from '@shared/types/config-service';
import { IDetailsDialogViewModel } from '@shared/types/details-dialog-vm';
import { INotificationService } from '@shared/types/notification-service';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { IProductsService } from '@shared/types/products-service';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { formatDate } from '@shared/utils/date';

export class BaseDetailsViewModel implements IDetailsDialogViewModel {
  protected ordersSmartCoinService = appInject<IOrdersSmartCoinService>(
    DI_TOKENS.ordersSmartCoinService,
  );
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  protected walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  protected userService = appInject<IUsersService>(DI_TOKENS.usersService);
  protected ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  protected productsService = appInject<IProductsService>(DI_TOKENS.productsService);
  protected configService = appInject<IConfigService>(DI_TOKENS.configService);
  protected secure3DRepositoryService = appInject<ISecure3DRepositoryService>(
    DI_TOKENS.secure3DRepositoryService,
  );
  protected paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  protected smartCoinTrackingService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );

  protected smartCoinDetailsDialogVM = appInject<ISmartCoinDetailsDialogViewModel>(
    DI_TOKENS.smartCoinDetailsDialogViewModel,
  );

  protected _data = {
    orderStatus: null as SmartCoinOrderStatusModel | null,
    orderDetails: null as SmartCoinOrderDetailsModel | null,
    transactionDetails: null as TransacrtionDetailsModel | null,
    userDetails: null as UserDetailsModel | null,
  };

  constructor() {
    appMakeObservable(this, {
      _data: appObservable,
    });
  }

  get isPaymentMethodIsCard() {
    return true;
  }

  get currency() {
    return '';
  }

  get paymentMethod() {
    return '';
  }

  get paymentMethodBrand() {
    return '';
  }

  get paymentMethodLast4Digs() {
    return '';
  }

  get isValidPaymentMethod() {
    if (this.paymentMethod === 'Deposit') {
      return true;
    }
    if (this.paymentMethod === 'Card') {
      return Boolean(this.paymentMethodLast4Digs) && Boolean(this.paymentMethodBrand);
    }
    return false;
  }

  get blockchainExplorerLink() {
    return `${this.configService.blockchainViewerOrigin}`;
  }

  get orderStatus() {
    return this._data.orderStatus;
  }

  get orderDetails() {
    return this._data.orderDetails;
  }

  get transactionDetails() {
    return this._data.transactionDetails;
  }

  get userDetails() {
    return this._data.userDetails;
  }

  get type() {
    return this.smartCoinDetailsDialogVM.type;
  }

  get hasData() {
    return false;
  }

  get status() {
    return '';
  }

  get operationType() {
    return '';
  }

  get isBuy() {
    return false;
  }

  get quantity() {
    return '0';
  }

  get coinName() {
    return '';
  }

  get date() {
    return formatDate(new Date(), 'lll');
  }

  get declineTitle() {
    return '';
  }

  get declineMessage() {
    return '';
  }

  get rateTitle() {
    return '';
  }

  get rateValue() {
    return '0';
  }

  get price() {
    return '0';
  }

  get transactionType() {
    return '';
  }

  get transactionFee() {
    return 0;
  }

  get transactionFeeTextValue() {
    return '';
  }

  get totalAmountTextValue() {
    return '';
  }

  get id() {
    return '';
  }

  get blockchainTransactionId() {
    return '';
  }

  async fetchDetails(id: string): Promise<void> {
    this._data.orderStatus = null;
    this._data.orderStatus = await this.ordersSmartCoinService.getOrderStatus(id);

    this._data.orderDetails = null;
    this._data.orderDetails = await this.ordersSmartCoinService.getOrderDetails(id);

    this._data.transactionDetails = null;
    this._data.transactionDetails = await this.walletsService.getTransactionDetails(id);
  }

  async fetchUserDetails(): Promise<void> {
    Promise.resolve();
  }

  handle3DSecure = async () => {
    if (!this.orderStatus || !this.orderDetails) return;
    //##################################
    return await this.paymentService.handle3DSecure(
      this.orderStatus.paymentResult.response,
      this.orderDetails.asJson.id,
    );
  };

  async confirmSmartCoinOrder(): Promise<void> {
    if (!this.orderStatus || !this.orderDetails) return;
    return this.walletsService.confirmPaymentTransaction(
      this.orderStatus.asJson.paymentResult.transactionId,
      this.orderDetails.asJson.id,
    );
  }

  async declineSmartCoinOrder(): Promise<void> {
    if (!this.orderStatus || !this.orderDetails) return;
    return this.walletsService.declinePaymentTransaction(
      this.orderStatus.asJson.paymentResult.transactionId,
      this.orderDetails.asJson.id,
    );
  }

  subscribeToTrackingService(cb: () => void) {
    this.smartCoinTrackingService.subscribe(BaseDetailsViewModel.name, cb);
  }

  unsubscribe() {
    this.smartCoinTrackingService.unsubscribe(BaseDetailsViewModel.name);
  }

  isBlocked3DSecure() {
    return this.secure3DRepositoryService.isBlocked3DSecure(this.orderDetails?.asJson.id || '');
  }

  showMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }
}
