import React, { KeyboardEvent, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { NewTransferError } from '@modules/new-private/orders/new-transfer/new-transfer-form/new-transfers-errors';
import { NewTransferViewModel } from '@modules/new-private/orders/new-transfer/new-transfer.vm';
import { InputRecepient } from '@modules/new-private/orders/new-transfer/transfer-form/input-recepient';
import { DocumentDialog } from '@modules/new-private/orders/purchase-and-sell/document-dialog';
import { Theme, useMediaQuery } from '@mui/material';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { Wallet } from '@shared/components/new-design/select-payment-method/components/wallet/wallet';
import SubmitButton from '@shared/components/new-design/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { Layout } from '@shared/utils/layout';
import { formatAmount } from '@shared/utils/metals';
import { useMutation } from 'react-query';

import { styles } from './confirm-transfer.styles';

export interface ConfirmOrderProps extends AppWithStyles<typeof styles> {
  isLoading?: boolean;
  vm: NewTransferViewModel;
}

const ConfirmOrderComponent: React.FC<ConfirmOrderProps> = appObserver(
  ({ classes, isLoading, vm }) => {
    const $vm = useMemo(() => vm, []);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
    const [isOpenDocumentDialog, setIsOpenDocumentDialog] = useState<boolean>(false);

    const navigate = useNavigate();

    const navigateToDashboard = () => {
      isMobile
        ? navigate(ROUTES.mobilePrivate.dashboard)
        : navigate(ROUTES.desktopPrivate.dashboard);
    };

    if (!$vm.createdOrder) {
      return <></>;
    }

    const costOfTransactionBigNumber = $vm.calculateCostOfTransaction(
      $vm.createdOrder.amount,
      $vm.rate,
    );

    const confirmTransactionMutation = useMutation(
      () => $vm.handleConfirm($vm.createdOrder as any),
      {
        onSuccess: ({ id }) => {
          navigateToDashboard();
          $vm.startTracking(id);
        },
        onError: (e: HttpErrorResponse | any) => {
          $vm.serverError = NewTransferError.BLOCKED;
          $vm.showCanceledMessage(
            $vm.createdOrder?.amount || 0,
            $vm.getActiveWallet()?.currency || '',
          );
          $vm.toTransferForm();
        },
      },
    );

    const confirmTransaction = () => {
      confirmTransactionMutation.mutate();
    };

    const confirmButton = (
      <SubmitButton
        label={t`Confirm Transfer`}
        isLoading={isLoading || confirmTransactionMutation.isLoading}
        onSubmit={confirmTransaction}
      />
    );

    const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter') {
        confirmTransaction();
      }
    };

    const walletComponent = (
      <Wallet
        currency={$vm.getActiveWallet()?.currency || ''}
        balance={minDigitsAfterDot(amountPipe($vm.getActiveWallet()?.balance || 0, 8))}
      />
    );

    return (
      <>
        <DocumentDialog
          isOpen={isOpenDocumentDialog}
          onClose={() => setIsOpenDocumentDialog(false)}
          title={t`Transaction Information`}
          type={DocumentsTitle.TRANSFER}
          url={$vm.document?.url || ''}
        />
        <div className={classes.root} onKeyDown={handleKeyPress}>
          <div className={classes.backButton}>
            <BackButton onClick={() => $vm.toTransferForm()} />
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.title}>{t`Confirm Transfer`}</div>
            {$vm.document ? (
              <div
                className={classes.hintButton}
                onClick={() => setIsOpenDocumentDialog(true)}
              ></div>
            ) : (
              <></>
            )}
            <div className={classes.recipient}>
              <InputRecepient readOnly={true} recipient={$vm.recipient} />
            </div>
            <div className={classes.typeOfTransaction}>
              <div className={classes.infoField}>
                <div className={classes.fieldTitle}>{t`Type of Transaction`}</div>
                <div className={classes.value}>{t`Transfer`}</div>
              </div>
            </div>
            <div className={classes.generalInfoBlock}>
              <div className={classes.generalInfoField}>
                <div className={classes.fieldTitle}>{t`Quantity`} *</div>
                <div className={classes.value}>
                  {minDigitsAfterDot(amountPipe($vm.createdOrder.amount, 8))}{' '}
                  {$vm.getActiveWallet()?.currency || ''}
                </div>
              </div>
              <div className={classes.generalInfoField}>
                <div className={classes.fieldTitle}>
                  {t`Per`} 1 {$vm.getActiveWallet()?.currency || ''}
                </div>
                <div className={classes.value}>
                  {minDigitsAfterDot(amountPipe($vm.rate, 2))} {$vm.currency}
                </div>
              </div>
              <div className={classes.generalInfoField}>
                <div className={classes.fieldTitle}>{t`Transfer Price`}</div>
                <div className={classes.value}>
                  {formatAmount(costOfTransactionBigNumber.toFixed(2), 2)}{' '}
                  {$vm.getActiveWallet()?.currency || ''}
                </div>
              </div>
              <div className={`${classes.generalInfoField} ${classes.fee}`}>
                <div className={classes.fieldTitle}>{t`Transaction Fee`}</div>
                <div className={classes.value}>0.00 {$vm.currency}</div>
              </div>
            </div>
            <div className={classes.total}>
              <div className={classes.infoField}>
                <div className={classes.bold}>{t`Total`}:</div>
                <div className={classes.bold}>
                  {minDigitsAfterDot(amountPipe($vm.createdOrder.amount, 8))}{' '}
                  {$vm.getActiveWallet()?.currency || ''}
                </div>
              </div>
            </div>
            <div className={classes.paymentMethod}>{walletComponent}</div>
            <div className={classes.footnote}>
              * 1 {$vm.getActiveWallet()?.currency || ''} = 1 {t`gram of physical`}{' '}
              {$vm.getMetalNameBySmartCoinName($vm.getActiveWallet()?.currency || '')}
            </div>
          </div>
          {confirmButton}
        </div>
      </>
    );
  },
);

export default appWithStyles(styles)(ConfirmOrderComponent);
