import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { NewTransferError } from '@modules/new-private/orders/new-transfer/new-transfer-form/new-transfers-errors';
import { DI_TOKENS } from '@shared/constants/di';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { UserRestrictionReasonsEnum } from '@shared/enums/user-restriction-reasons.enum';
import { AuthGetModel } from '@shared/models/auth/get-model';
import { GetContactListModel } from '@shared/models/contacts/get-contact-model-model';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { OrderListQuery } from '@shared/models/orders/list-model';
import { ProductType } from '@shared/models/products/product-model';
import { UserDetailsDTO, UserDetailsModel } from '@shared/models/users/details-model';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';
import { UserStatus } from '@shared/models/users/user-status';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IAuthService } from '@shared/types/auth-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { ITransactionsNotificationService } from '@shared/types/transactions-notification-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { amountToBigNumber, formatAmount, sanitizedAmount } from '@shared/utils/metals';
import { BigNumber } from 'bignumber.js';

import { Screen } from './screens';
import { WalletItem } from './wallet-item';
import { Currency } from '@shared/models/wallets/currency';

export class NewTransferViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private smartCoinTrackingService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  private _wallet: WalletItem;
  private _smartCoinsWallets: WalletsListModel[] = [];
  private _me: AuthGetModel | null = null;
  private _contacts: GetContactListModel[] = [];
  private _createdOrder: {
    orderId: OrderListQuery['id'];
    recipientUserId: UserDetailsDTO['id'];
    amount: number;
    savedRecipient: boolean;
  } | null = null;
  private _activeScreen = {
    data: Screen.TRNSFER_FORM as Screen,
  };
  private transactionsNotificaionService = appInject<ITransactionsNotificationService>(
    DI_TOKENS.transactionsNotificationService,
  );
  private _wallets = {
    data: [] as WalletItem[],
  };
  private _document = null as TermsDocumentModel | null;
  private _restrictions = {
    data: <UserRestrictionReasonsEnum | null>UserRestrictionReasonsEnum.CHECK_IN_PROGRESS,
  };
  private _recipient = {
    data: null as UserDetailsModel | null,
  };

  private _userError = {
    data: null as NewTransferError | null,
  };

  private _maintenanceMode = {
    isActive: false as boolean,
  };

  serverError: NewTransferError | null = null;

  constructor() {
    appMakeObservable(this, {
      _activeScreen: appObservable,
      _recipient: appObservable,
      _userError: appObservable,
      _wallets: appObservable,
      _maintenanceMode: appObservable,
    });
  }

  get isActiveMaintenanceMode() {
    return this._maintenanceMode.isActive;
  }

  get createdOrder() {
    return this._createdOrder;
  }

  get document() {
    return this._document;
  }

  get userError() {
    return this._userError.data || undefined;
  }

  setUserError(value: NewTransferError) {
    this._userError.data = value;
  }

  get activeScreen() {
    return this._activeScreen.data;
  }

  get restrictions() {
    return this._restrictions;
  }

  get rate(): number {
    return this.ratesVM.rate[this.currency] as number;
  }

  get currency() {
    return this.ratesVM.currency;
  }

  get recipient() {
    return this._recipient.data as UserDetailsModel;
  }

  get contacts() {
    return this._contacts;
  }

  get isDisabledSaveToggle(): boolean {
    const existsRecipient = this.contacts.find(
      (item) => item.asJson.id === this._recipient.data?.asJson.id,
    );
    return !this.recipient || Boolean(existsRecipient);
  }

  get wallet() {
    return this._wallet;
  }

  get isConfirmationBlocked() {
    return Boolean(this._restrictions.data) || Boolean(this._userError.data);
  }

  startTracking(orderId: string) {
    this.smartCoinTrackingService.startTracking({
      orderId: orderId,
      amount: this.createdOrder?.amount || 0,
      smartCoin: Currency.GSC,
      isBuy: false,
      isSend: true,
      hideFirstMessage: true,
    });
  }

  removeCreatedOrder() {
    this._createdOrder = null;
  }

  getConnectedData = async () => {
    const wallets = await this.walletsService.getList(this.ratesVM.currency);
    this._smartCoinsWallets = wallets.filter(
      (wallet) =>
        this.productsVM.getProductTypeByCurrency(wallet.asJson.currency) === ProductType.SMART_COIN,
    );
    this._wallets.data = this._smartCoinsWallets.map((wallet) => ({
      balance: wallet.asJson.balance,
      currency: wallet.asJson.currency,
      externalTransactionId: null,
      walletId: wallet.asJson.id,
    }));
    await this.fetchMaintenanceModeStatus();
    this._contacts = await this.usersService.getContacts();
    this._me = await this.authService.getUserInfo(false);
  };

  handleConfirm = async (data: {
    orderId: OrderListQuery['id'];
    recipientUserId: UserDetailsDTO['id'];
    amount: number;
    savedRecipient: boolean;
  }): Promise<{ id: string }> => {
    const { amount, recipientUserId, savedRecipient } = data;

    return await this.walletsService.createP2PTransfer(
      recipientUserId.trim(),
      sanitizedAmount(amount),
      savedRecipient as boolean,
      this.getActiveWallet()?.currency || '',
    );
  };

  async validateRecipient(recipientId: string) {
    this._recipient.data = null;
    if (
      this._userError.data &&
      [
        NewTransferError.BLOCKED,
        NewTransferError.AWAIT_DELETION,
        NewTransferError.TRANSFER_TO_SELF,
        NewTransferError.NOT_FOUND,
      ].includes(this._userError.data)
    ) {
      this._userError.data = null;
    }

    if (!recipientId) {
      return;
    }
    try {
      this._recipient.data = await this.usersService.getUserDetails(recipientId);
      if (this._recipient.data.asJson.userStatus === UserStatus.Blocked) {
        this._userError.data = NewTransferError.BLOCKED;
      }
      if (this._recipient.data.asJson.userStatus === UserStatus.DeletionRequested) {
        this._userError.data = NewTransferError.AWAIT_DELETION;
      }
      if (recipientId === this._me?.asJson.id) {
        this._userError.data = NewTransferError.TRANSFER_TO_SELF;
        return;
      }
    } catch (e: HttpErrorResponse | any) {
      console.error(e);
      this._userError.data = NewTransferError.NOT_FOUND;
    }
  }

  async fetchMaintenanceModeStatus() {
    await this.usersService.fetchMaintenanceModeStatus();
    this._maintenanceMode.isActive = this.usersService.isActiveMaintenanceMode;
  }

  validateTransferSize(amount: number) {
    const wallet = this.getActiveWallet();
    if ((wallet?.balance || 0) < sanitizedAmount(amount)) {
      this._userError.data = NewTransferError.AMOUNT_GREATE_THAN_BALANCE;
    } else if (this._userError.data === NewTransferError.AMOUNT_GREATE_THAN_BALANCE) {
      this._userError.data = null;
    }
  }

  checkUserStatus = async () => {
    const user = await this.usersService.getMyUserDetails();
    this._restrictions.data = user.restrictions;
  };

  getProductTypeByCurrency(currency: string) {
    return this.productsVM.getProductTypeByCurrency(currency);
  }

  getProductByCurrency(currency: string) {
    return this.productsVM.getProductByCurrency(currency);
  }

  getActiveWallet() {
    return this._wallets.data.find(
      (w) => this.productsVM.getProductTypeByCurrency(w.currency) === ProductType.SMART_COIN,
    );
  }

  removeRecepient() {
    this._recipient.data = null;
    if (
      this._userError.data &&
      [
        NewTransferError.BLOCKED,
        NewTransferError.AWAIT_DELETION,
        NewTransferError.TRANSFER_TO_SELF,
        NewTransferError.NOT_FOUND,
      ].includes(this._userError.data)
    ) {
      this._userError.data = null;
    }
  }

  calculateFiatCurrencyAmount(smartCoinAmount: string): string {
    if (!this.ratesVM.rate || !this.ratesVM.rate[this.ratesVM.currency]) return '0';
    const amountBigNumber = amountToBigNumber(smartCoinAmount);
    const rateBigNumber = new BigNumber(this.ratesVM.rate[this.ratesVM.currency] || 0);
    const currentSum = amountBigNumber.multipliedBy(rateBigNumber);
    return formatAmount(currentSum.toFixed(2), 2);
  }

  getMetalNameBySmartCoinName(smartCoinName: string): string {
    return (
      this.productsVM.products.find((product) => product.asJson.name === smartCoinName)?.asJson
        .baseCurrency || 'Metal'
    );
  }

  calculateCostOfTransaction(amount: number, count: number): BigNumber {
    if (!amount || !count) return amountToBigNumber('0.00');
    const amountBigNumber = amountToBigNumber(`${amount}`);
    const countBigNumber = amountToBigNumber(`${count}`);
    return amountBigNumber.multipliedBy(countBigNumber);
  }

  toTransferForm() {
    this._activeScreen.data = Screen.TRNSFER_FORM;
  }

  submitTransferForm(data: {
    orderId: OrderListQuery['id'];
    recipientUserId: UserDetailsDTO['id'];
    amount: number;
    savedRecipient: boolean;
  }) {
    this._createdOrder = data;
    this._activeScreen.data = Screen.CONFIRM_SCREEN;
  }

  async fetchDocument() {
    this._document = await this.usersService.getDocument(DocumentsTitle.TRANSFER);
  }

  showSuccessfulMessage(amount: number, currency: string) {
    this.showMessage(amount, currency, 'successful');
  }

  showInProgressMessage(amount: number, currency: string) {
    this.showMessage(amount, currency, 'inProgress');
  }

  showCanceledMessage(amount: number, currency: string) {
    this.showMessage(amount, currency, 'canceled');
  }

  private showMessage(amount: number, currency: string, status: string) {
    this.transactionsNotificaionService.showNotification({
      id: '',
      amount,
      currency,
      status,
      type: TransactionMessageType.TRANSFER,
    });
  }
}
