import React, { FC, useEffect, useMemo } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputRecepient } from '@modules/new-private/orders/new-transfer/transfer-form/input-recepient';
import { TransactionDetailsViewModel } from '@modules/new-private/orders/order-details-container/transaction-details.vm';
import { TreeItem, TreeView } from '@mui/lab';
import { Loading } from '@shared/components/loading';
import ExpandIcon from '@shared/components/new-design/images/expand-arrow-bottom-grey.svg';
import CollapsIcon from '@shared/components/new-design/images/expand-arrow-right-grey.svg';
import { Card } from '@shared/components/new-design/select-payment-method/components/card/card';
import { Wallet } from '@shared/components/new-design/select-payment-method/components/wallet/wallet';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';
import clipboardCopy from 'clipboard-copy';
import { useMutation, useQuery } from 'react-query';

import { OrderDetailsViewModel } from '../order-details-container/order-details.vm';

import { styles } from './order-details.styles';

export interface OrderDetailsProps extends AppWithStyles<typeof styles> {
  orderId: string;
  type: OrderDetailsType;
  vm: OrderDetailsViewModel | TransactionDetailsViewModel;
}

const OrderDetailsComponent: FC<OrderDetailsProps> = ({ classes, orderId, vm, type }) => {
  const $vm = useMemo(() => vm, []);

  const smartCoinDetailsDialogVM = appInject<ISmartCoinDetailsDialogViewModel>(
    DI_TOKENS.smartCoinDetailsDialogViewModel,
  );

  const fetchDetailsQuery = useQuery(
    ['fetch-details-query'],
    () => $vm.fetchDetails(orderId as string),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onSuccess: () => {
        fetchUserDetailsQuery.refetch();
      },
    },
  );

  const fetchUserDetailsQuery = useQuery(
    ['fetch-user-details-query'],
    () => $vm.fetchUserDetails(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
    },
  );

  if (fetchDetailsQuery.isError) {
    throw 'Error';
  }

  useEffect(() => {
    $vm.subscribeToTrackingService(() => fetchDetailsQuery.refetch());
    return () => {
      $vm.unsubscribe();
    };
  }, []);

  const handleCopy = async (value?: string) => {
    if (value) await clipboardCopy(value);
    $vm.showMessage(t`Information was copied in buffer`);
  };

  const confirmSmartCoinOrderMutation = useMutation(() => $vm.confirmSmartCoinOrder());

  const declineSmartCoinOrderMutation = useMutation(() => $vm.declineSmartCoinOrder());

  const secure3DMutation = useMutation(() => $vm.handle3DSecure(), {
    onSuccess: () => confirmSmartCoinOrderMutation.mutate(),
    onError: () => declineSmartCoinOrderMutation.mutate(),
    onSettled: () => smartCoinDetailsDialogVM.finish3DSecure(),
  });

  const handle3DSecure = () => {
    smartCoinDetailsDialogVM.start3DSecure();
    secure3DMutation.mutate();
  };

  const walletComponent = <Wallet currency={$vm.currency} isDetailsView={true} balance={''} />;

  const cardComponent = (
    <Card
      brand={$vm.paymentMethodBrand}
      lastDigits={$vm.paymentMethodLast4Digs}
      isExpired={false}
    />
  );

  const paymentMethodComponent = $vm.isPaymentMethodIsCard ? cardComponent : walletComponent;

  if (fetchDetailsQuery.isLoading || fetchUserDetailsQuery.isFetching || !$vm.hasData) {
    return (
      <div className={classes.loading}>
        <Loading size={70} />
      </div>
    );
  }

  let transactionTypeClass = '';
  switch (type) {
    case OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN:
      transactionTypeClass = $vm.isBuy ? classes.purchase : classes.sellout;
      break;
    case OrderDetailsType.DEPOSIT_FIAT_COIN:
      transactionTypeClass = classes.deposit;
      break;
    case OrderDetailsType.WITHDRAWAL_FIAT_COIN:
      transactionTypeClass = classes.sellout;
      break;
    case OrderDetailsType.SENDING_SMART_COIN:
      transactionTypeClass = classes.sent;
      break;
    case OrderDetailsType.RECEIVING_SMART_COIN:
      transactionTypeClass = classes.receive;
      break;
    default:
      transactionTypeClass = classes.sellout;
  }

  let statusText = '';
  let statusClass = '';
  switch ($vm.status) {
    case SmartCoinOrderStatusExternal.NEW:
    case SmartCoinOrderStatusExternal.IN_PROGRESS:
      statusClass = classes.inProgress;
      statusText = t`In Progress`;
      break;
    case SmartCoinOrderStatusExternal.CANCELED:
      statusClass = classes.canceled;
      statusText = t`Canceled`;
      break;
    case SmartCoinOrderStatusExternal.EXECUTED:
      statusClass = classes.executed;
      statusText = t`Executed`;
      break;
    case SmartCoinOrderStatusExternal.SECURE_3D:
      statusClass = classes.inProgress;
      statusText = t`Required 3DS`;
      break;
  }

  return (
    <div className={`${classes.root} ${statusClass} ${transactionTypeClass}`}>
      <div>
        <div className={classes.statusIconBlock}>
          <div className={classes.statusIconBackground}>
            <div className={classes.statusIconImage}></div>
          </div>
        </div>
        <div className={classes.operationAmount}>
          {$vm.quantity} {$vm.coinName}
        </div>
        <div className={classes.dateTime}>{$vm.date}</div>
        <div className={classes.currentStatusBlock}>
          <div className={classes.currentStatusFieldName}>{t`Status`}</div>
          <div className={classes.currentStatusFieldValueAndInfo}>
            <div className={classes.currentStatusFieldValue}>{statusText}</div>
          </div>
        </div>
        <div className={classes.infoBlock}>
          {$vm.status === SmartCoinOrderStatusExternal.CANCELED ? (
            <div className={classes.declineInfoBlock}>
              <TreeView
                className={classes.declineInfoTree}
                defaultCollapseIcon={<img src={String(ExpandIcon)} alt="" />}
                defaultExpandIcon={<img src={String(CollapsIcon)} alt="" />}
              >
                <TreeItem
                  nodeId="1"
                  label={<div className={classes.declineInfoLabel}>{$vm.declineTitle}</div>}
                >
                  {$vm.declineMessage !==
                  'Transaction declined, please contact support@wealthstack.com for more information.' ? (
                    <div className={classes.declineInfoMessage}>{$vm.declineMessage}</div>
                  ) : (
                    <div className={classes.declineInfoMessage}>
                      Transaction declined, please contact{' '}
                      <a href={'mailto:support@wealthstack.com'}>support@wealthstack.com</a> for
                      more information.
                    </div>
                  )}
                </TreeItem>
              </TreeView>
            </div>
          ) : (
            <></>
          )}
          {$vm.userDetails &&
          [OrderDetailsType.SENDING_SMART_COIN, OrderDetailsType.RECEIVING_SMART_COIN].includes(
            type,
          ) ? (
            <div className={classes.recipient}>
              <InputRecepient readOnly={true} recipient={$vm.userDetails as UserDetailsModel} />
            </div>
          ) : (
            <></>
          )}
          <div className={classes.infoBlock}>
            <div className={classes.typeOfTransaction}>
              <div className={classes.infoField}>
                <div className={classes.fieldTitle}>{t`Transaction type`}</div>
                <div className={classes.value}>{$vm.transactionType}</div>
              </div>
            </div>
            {[
              OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN,
              OrderDetailsType.WITHDRAWAL_FIAT_COIN,
              OrderDetailsType.DEPOSIT_FIAT_COIN,
            ].includes(type) ? (
              <div className={classes.generalInfoBlock}>
                <div className={classes.generalInfoField}>
                  <div className={classes.fieldTitle}>{t`Quantity`} *</div>
                  <div className={classes.value}>
                    {$vm.quantity} {$vm.coinName}
                  </div>
                </div>
                <div className={classes.generalInfoField}>
                  <div className={classes.fieldTitle}>{$vm.rateTitle}</div>
                  <div className={classes.value}>{$vm.rateValue}</div>
                </div>
                <div className={classes.generalInfoField}>
                  <div className={classes.fieldTitle}>
                    {$vm.isBuy ? t`Purchase Price` : t`Sales price`}
                  </div>
                  <div className={classes.value}>{$vm.price}</div>
                </div>
                <div className={`${classes.generalInfoField} ${classes.fee}`}>
                  <div className={classes.fieldTitle}>{t`Payment Card Fee`}</div>
                  <div className={classes.value}>
                    {$vm.isBuy || $vm.transactionFee === 0 ? '' : '-'} {$vm.transactionFeeTextValue}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.generalInfoBlock}>
                <div className={classes.generalInfoField}>
                  <div className={classes.fieldTitle}>{t`Quantity`}</div>
                  <div className={classes.value}>
                    {$vm.quantity} {$vm.coinName}
                  </div>
                </div>
                <div className={classes.generalInfoField}>
                  <div className={classes.fieldTitle}>{t`Transaction Fee`}</div>
                  <div className={classes.value}>
                    {$vm.isBuy || $vm.transactionFee === 0 ? '' : '-'} {$vm.transactionFeeTextValue}
                  </div>
                </div>
              </div>
            )}
            <div className={classes.total}>
              <div className={classes.infoField}>
                <div className={classes.bold}>{t`Total`}:</div>
                <div className={classes.bold}>{$vm.totalAmountTextValue}</div>
              </div>
            </div>
          </div>
        </div>
        {$vm.isValidPaymentMethod ? (
          <div className={classes.paymentMethod}>{paymentMethodComponent}</div>
        ) : (
          <></>
        )}
        <div className={classes.transactionIdBlock}>
          <TreeView
            className={classes.transactionIdTree}
            defaultCollapseIcon={<img src={String(ExpandIcon)} alt="" />}
            defaultExpandIcon={<img src={String(CollapsIcon)} alt="" />}
          >
            <TreeItem
              nodeId="1"
              label={<div className={classes.transactionIdLabel}>{t`Transaction ID`}</div>}
            >
              <div className={classes.transactionId} onClick={() => handleCopy($vm.id)}>
                {$vm.id}
              </div>
            </TreeItem>
          </TreeView>
        </div>
        {$vm.blockchainTransactionId ? (
          <a
            className={classes.blockchainButton}
            target="_blank"
            href={`${vm.blockchainExplorerLink}/transaction/${$vm.blockchainTransactionId}`}
          >{t`Show in blockchain`}</a>
        ) : (
          <></>
        )}
      </div>
      {$vm.status === SmartCoinOrderStatusExternal.SECURE_3D ? (
        <div className={classes.secure3D}>
          <SubmitButton
            label={t`Accept 3DS`}
            onSubmit={handle3DSecure}
            disabled={$vm.isBlocked3DSecure()}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default appWithStyles(styles)(OrderDetailsComponent);
